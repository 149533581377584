.infinite-card {
    height: 100%;
    padding: 2% 1%;
    position: relative;
    display: inline-block;
}

.infinite-card-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    position: relative;
    width: 100%;

    .scrolling-wrapper {
        height: 100%;
        max-height: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}
