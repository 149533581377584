.detail-frame {
    width: 45%;
    max-height: 100%;
    z-index: 1;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    padding: 3em 3em 0 3em;
    margin-right: 5%;
}

.title-frame {
    &:before {
        content: '';
        float: right;
    }

    div {
        float: right;
        clear: both;
        width: 50%;
        height: 3em;
        border-bottom-left-radius: 1em;
        border-bottom-right-radius: 1em;
        background-color: white;
    }
}

.description-home {
    &:before {
        content: '';
        float: right;
    }

    &:after {
        height: 100%;
    }

    div {
        float: right;
        clear: both;
        width: 50%;
        height: 3em;
        background-color: white;
    }
}
