@font-face {
    font-family: 'Neuropol';
    src: url('/fonts/Neuropol.ttf.woff') format('woff'),
        url('/fonts/Neuropol.ttf.svg#Neuropol') format('svg'),
        url('/fonts/Neuropol.ttf.eot'),
        url('/fonts/Neuropol.ttf.eot?#iefix') format('embedded-opentype');
    font-style: normal !important;
}

* {
    user-select: none;
}
