.validated {
    & label.MuiInputLabel-root {
        color: green;
    }

    & .MuiFilledInput-underline::after {
        border-bottom-color: green;
        -webkit-transform: scaleX(1);
        -moz-transform: scaleX(1);
        -ms-transform: scaleX(1);
        transform: scaleX(1);
    }

    & .MuiCheckbox-root {
        color: green !important;
    }
}
