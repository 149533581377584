.modal-xl {
    max-width: 85vw !important;
}

.modal-lg {
    max-width: 75vw !important;
}

.modal-body {
    font-size: 150%;

    .MuiStepIcon-text {
        font-size: inherit;
    }

    .MuiStepLabel-label {
        font-size: inherit;
    }

    .MuiFormControlLabel-label {
        font-size: inherit;
    }

    button {
        font-size: inherit;
    }
}

.modal-footer {
    font-size: 150%;

    button {
        font-size: inherit;
    }
}
